import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'gur-ilnostroteam',
  templateUrl: './ilnostroteam.component.html',
  styleUrls: ['./ilnostroteam.component.css']
})
export class IlnostroteamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
