import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';
import {report} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor() {
  }

}

export function saveFileToFileSystem(pdfUrl: string,  filename: string): void {
  saveAs(pdfUrl, filename);
}

export function saveToFileSystem(optionType: string, filename: string, response: any): void {
  const blob = new Blob([response], {type: optionType});
  saveAs(blob, filename);
}

export function savePDFToFileSystem(response, filename: string): void {
  saveToFileSystem('application/pdf', filename + '.' + report.extension.pdf, response);
}

export function saveWordToFileSystem(response: any, filename: string): void {
  saveToFileSystem('application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    filename + '.' + report.extension.word, response);
}
