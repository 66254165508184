<div id="contact" class="contact-form">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="text-container">
          <div class="section-title">Contattaci</div>
          <h2>Entra in contatto con noi utilizzando il form</h2>
          <p>Siamo reperibili dal Lunedì al Venerdì dalle 09:00 alle 18:30. <br>
            Risponderemo a tutte le richieste di informazioni via e-mail entro 24 ore.</p>
          <ul class="list-unstyled li-space-lg">
            <li class="address"><i class="fas fa-map-marker-alt"></i>
              01033 Civita Castellana, Viterbo - Italia
            </li>
            <li><i class="fas fa-envelope"></i><a href="mailto:info@gurobo.it">info&#64;gurobo.it</a></li>
            <br>
            <li><a href="https://www.linkedin.com/company/gurobo" target=”_blank”><i class="fab fa-linkedin"></i>Linkedin</a><li>
            <li><a href="https://www.instagram.com/gurobo_4factories/" target=”_blank”><i class="fab fa-instagram"></i>Instagram</a><li>
            <li><a href="https://www.facebook.com/GuroboSrls" target=”_blank”><i class="fab fa-facebook"></i>Facebook</a><li>
            <li><a href="https://www.youtube.com/@GuRoBo" target=”_blank”><i class="fab fa-youtube"></i>Youtube</a><li>
          </ul>
          <!--          <h3>Oppure contattaci tramite i nostri Social Media</h3>

                    <span class="fa-stack">
                                                <a href="#your-link">
                                                    <span class="mini-circle-icon"></span>
                                                    <i class="fab fa-facebook-f fa-stack-1x secondary-color"></i>
                                                </a>
                                            </span>
                    <span class="fa-stack">
                                                <a href="#your-link">
                                                    <span class="mini-circle-icon"></span>
                                                    <i class="fab fa-twitter fa-stack-1x secondary-color"></i>
                                                </a>
                                            </span>
                    <span class="fa-stack">
                                                <a href="#your-link">
                                                    <span class="mini-circle-icon"></span>
                                                    <i class="fab fa-linkedin-in fa-stack-1x secondary-color"></i>
                                                </a>
                                            </span>-->
        </div>
      </div>
      <div class="col-lg-6">

        <!-- Contact Form -->
        <form #contactForm="ngForm" id="contactForm" data-toggle="validator" data-focus="false">
          <div class="form-group">
            <input type="text" class="form-control-input" id="ragioneSociale" name="ragioneSociale"
                   [(ngModel)]="contattaciObj.name"
                   [disabled]="disableForm"
                   [ngClass]="contattaciObj.name ? 'notClear' : null" #ragionesociale="ngModel" required>
            <label class="label-control" for="ragioneSociale">Nome\Ragione Sociale</label>
            <p *ngIf="ragionesociale && (ragionesociale.dirty || ragionesociale.touched)"
               class="text-danger col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <small *ngIf="ragionesociale.errors?.required">
                Attenzione, &egrave; necessario inserire un nome o una ragione sociale
              </small>
              <small *ngIf="contattaciObj?.name?.length > 100">
                Attenzione, la Ragione Sociale\Nome non può essere superiore a 100 caratteri
              </small>
            </p>
          </div>
          <div class="form-group">
            <input type="email" class="form-control-input" id="email" name="email"
                   [(ngModel)]="contattaciObj.email"
                   [disabled]="disableForm" [ngClass]="contattaciObj.email ? 'notClear' : null"
                   #email="ngModel" [pattern]="patternEmail" required>
            <label class="label-control" for="email">Email</label>
            <p *ngIf="email && (email.dirty || email.touched)"
               class="text-danger col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <small *ngIf="email.errors?.required">
                Attenzione, &egrave; necessario inserire una email
              </small>
              <small *ngIf="email.invalid && email.errors?.pattern">
                Attenzione, inserire una Email valida
              </small>
            </p>
          </div>
          <div class="form-group">
            <textarea class="form-control-textarea" id="message" name="message"
                      [(ngModel)]="contattaciObj.message"
                      [disabled]="disableForm"
                      [ngClass]="contattaciObj.message ? 'notClear' : null"
                      #messaggio="ngModel" required></textarea>
            <label class="label-control" for="message">Il tuo messaggio</label>
            <p *ngIf="messaggio && (messaggio.dirty || messaggio.touched)"
               class="text-danger col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <small *ngIf="messaggio.errors?.required">
                Attenzione, &egrave; necessario inserire un messaggio
              </small>
            </p>
          </div>
          <div class="form-group">
            <button type="submit" class="form-control-submit-button"
                    [disabled]="!contactForm.form.valid || contattaciObj?.name?.length > 100 || disableForm"
                    (click)="sendEmail()">
              INVIA
            </button>
          </div>
          <div class="form-message">
            <div id="cmsgSubmit" class="h3 text-center hidden"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

