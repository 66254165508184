import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FourFactoryDetailsComponent} from './components/four-factory-details/four-factory-details.component';
import {HomeComponent} from './components/home/home.component';
import {FourOrdersDetailsComponent} from './components/four-orders-details/four-orders-details.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: '4factories', component: FourFactoryDetailsComponent,
  },
  {
    path: '4Orders',
    component: FourOrdersDetailsComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
