<header id="header" class="header">
  <div class="video">
    <video src="assets/video/video.m4v" autoPlay muted loop>
      <source type="video/mp4"/>
    </video>
  </div>
  <div class="header-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-container">
            <img class="img-fluid" width="600" height="600" src="assets/img/Logo.png" alt="alternative">
            <div class="row">
              <div id="messenger"></div>
            </div>
            <a class="btn-solid-lg scrolling-page" href="#ourproducts">Scopri i nostri prodotti</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
