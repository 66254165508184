import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CommonMessageService} from '../../../service/util/common-message.service';

@Component({
  selector: 'gur-contattaci',
  templateUrl: './contattaci.component.html',
  styleUrls: ['./contattaci.component.css']
})
export class ContattaciComponent implements OnInit {

  constructor(private http: HttpClient,
              private messageService: CommonMessageService) {
  }

  public disableForm: boolean = false;

  public contattaciObj = {
    name: '',
    email: '',
    message: ''
  };

  public patternEmail = new RegExp('^[\\w-\']+(\\.[\\w-\']+)*@([A-z0-9-]+(\\.[A-z0-9-]+)*?\\.[A-z]{2,6}|(\\d{1,3}\\.){3}\\d{1,3})(:\\d{4})?$');

  ngOnInit(): void {
  }

  public sendEmail(): void {
    const pattern = new RegExp(this.patternEmail);
    if (!this.contattaciObj.name || this.contattaciObj?.name?.length > 100
      || !this.contattaciObj.email || !pattern.test(this.contattaciObj.email)
      || !this.contattaciObj.message) {
      this.messageService.setErrorMessage('Attenzione, si prega di verificare la correttezza dei' +
        ' dati inseriti nel form');
    } else {
      this.disableForm = true;
      const formData: any = new FormData();
      formData.append('name', this.contattaciObj.name);
      formData.append('email', this.contattaciObj.email);
      formData.append('message', this.contattaciObj.message);
      this.http.post('https://script.google.com/macros/s/AKfycbw_PD7u4Mf2-naz7w7qUU-LqvuGq1GC6SOJHquN6Q9JySj7JII/exec',
        formData).subscribe({
        error: () => {
          this.disableForm = false;
          this.messageService.setErrorMessage('Si è verificato un errore durante l\'invio della comunicazione.' +
            ' La invitiamo a scrivere una mail direttamente a info@gurobo.it. Ci scusiamo per il disagio');
        },
        next: () => {
          this.disableForm = false;
          this.messageService.setSuccessMessage('La sua richiesta è stata inoltrata con successo,' +
            ' risponderemo il prima possibile. Grazie per averci contattato.');
          this.contattaciObj = {
            name: '',
            email: '',
            message: ''
          };
        }
      });
    }

  }

}
