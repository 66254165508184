<gur-menu [isNotHome]="true"></gur-menu>

<header id="header" class="ex-header">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img class="img-fluid center-element" width="125" height="125" src="assets/img/4orders1280.png"
             alt="alternative">
        <h1><span class="secondary-color font-weight-bold">4</span>Orders</h1>
        <p class="secondary-color">Il portale per il B2B</p>
      </div>
    </div>
  </div>
</header>


<div class="breadcrumbs-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="breadcrumbs">
          <a href="index.html">Home</a><i class="fa fa-angle-double-right"></i><span
          class="secondary-color font-weight-bold">4</span><span>Orders</span>
        </div>
      </div>
    </div>
  </div>
</div>


<div id="chisiamo" class="functions">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="text-container">
          <div class="section-title"></div>
          <h2><i class="far fa-question-circle secondary-color"></i> COS’È <span
            class="secondary-color font-weight-bold">4</span>ORDERS</h2>
          <p class="text-justify">4Orders è un portale web che permette alla direzione, clienti ed agenti di un’azienda,
            di gestire in totale autonomia:
          </p>
          <div class="row">
            <div class="col-md-12">
              <ul class="list-unstyled li-space-lg indent">
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Ordini e preventivi dal catalogo dei prodotti</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Consultare e scaricare documenti in formato PDF</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Aggiornare la propria anagrafica e quella dei propri clienti
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p class="text-justify">4Orders è dunque uno spazio virtuale che fornisce tutti gli strumenti per far
            dialogare in modo semplice ed efficace l’azienda.</p>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="image-container">
          <img class="img-fluid" src="assets/img/4OrdersHome.png" alt="alternative">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sub-functions">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
                        <span class="fa-stack">
                            <span class="mini-circle-icon">
                              <i class="fas fa-euro-sign fa-stack-1x  secondary-color"></i>
                            </span>
                        </span>
          <div class="card-body">
            <h4 class="card-title">Tiene sotto controllo i<span
              class="secondary-color font-weight-bold"> profitti</span>
            </h4>
          </div>
        </div>

        <div class="card">
                        <span class="fa-stack">
                            <span class="mini-circle-icon"></span>
                            <i class="far fa-clock fa-stack-1x  secondary-color"></i>
                        </span>
          <div class="card-body">
            <h4 class="card-title">Monitora gli ordini e le<span
              class="secondary-color font-weight-bold"> scadenze</span>
            </h4>
          </div>
        </div>

        <div class="card">
                        <span class="fa-stack">
                          <span class="mini-circle-icon"></span>
                          <i class="fas fa-hands-helping fa-stack-1x  secondary-color"></i>
                        </span>
          <div class="card-body">
            <h4 class="card-title">Gestisce i flussi B2B con i<span
              class="secondary-color font-weight-bold"> clienti</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="whatis grey-background-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-7 col-lg-7">
        <div class="text-container">
          <h3><i class="fas fa-search secondary-color"></i> PRINCIPALI FUNZIONALITA'</h3>
          <p class="text-justify">Scegliendo 4Orders avrai a disposizione:</p>
          <div class="row">
            <div class="col-md-12">
              <ul class="list-unstyled li-space-lg indent">
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Maschere Web per la gestione di ordini e preventivi</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Sezioni dedicate alla gestione delle anagrafiche di clienti, contatti e
                    agenti
                  </div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Aggiornamento in tempo reale degli articoli</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Interfacce multi lingua e multi utente</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Accessibilità ovunque ti trovi da PC o Tablet
                  </div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Possibilit&agrave; di integrazione con software di fatturazione</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-5 d-sm-none d-md-block d-none d-sm-block">
        <img class="img-fluid" src="assets/img/img-4o.jpg" alt="alternative">
      </div>
    </div>
  </div>
</div>

<gur-contattaci></gur-contattaci>

<gur-footer></gur-footer>
