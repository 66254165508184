import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'gur-prodotti',
  templateUrl: './prodotti.component.html',
  styleUrls: ['./prodotti.component.css']
})
export class ProdottiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
