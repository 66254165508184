<gur-menu [isNotHome]="true"></gur-menu>

<header id="header" class="ex-header">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img class="img-fluid center-element" width="125" height="125" src="assets/img/4f_white.png" alt="alternative">
        <h1><span class="secondary-color font-weight-bold">4</span>Factories</h1>
        <p class="secondary-color">Il software per la ceramica 4.0</p>
        <div class="button-wrapper">
          <a class="btn-solid-link page-scroll" style="cursor: pointer" (click)="printBrochure()">Scarica la
            Brochure</a>
        </div>
      </div>
    </div>
  </div>
</header>


<div class="breadcrumbs-container">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="breadcrumbs">
          <a href="index.html">Home</a><i class="fa fa-angle-double-right"></i><span
          class="secondary-color font-weight-bold">4</span><span>Factories</span>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="whatis">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-lg-3 d-sm-none d-md-block d-none d-sm-block">
        <img class="img-fluid" width="250" height="250" src="assets/img/4f.png" alt="alternative">
      </div>
      <div class="col-sm-12 col-md-9 col-lg-9">
        <div class="text-container">
          <h3><i class="far fa-question-circle secondary-color"></i> COS’È 4FACTORIES</h3>
          <p class="text-justify">4Factories è un software gestionale che nasce per governare e ottimizzare tutto il
            processo di
            produzione all’interno di una fabbrica che produce ceramica sanitaria.
            L'applicativo è dunque in grado di controllare tutte le fasi produttive, dalla materia prima al prodotto
            finito, portando agli utilizzatori una serie di vantaggi quali:</p>
          <div class="row">
            <div class="col-md-12">
              <ul class="list-unstyled li-space-lg indent">
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Tempi di implementazione rapidi</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Interfaccia utente semplice ed intuitiva</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Sistema aperto e configurabile
                  </div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Facilità di integrazione con altri sistemi</div>
                </li>
                <li class="media">
                  <i class="fas fa-square"></i>
                  <div class="media-body">Capacità di collegare macchine, persone e sistemi rispondendo ai requisiti
                    dell’Industria
                    4.0
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="about" class="counter">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-lg-5">
        <h3 class="module-title"><i class="fas fa-chart-line secondary-color"></i> Dashboard</h3>
        <p class="text-justify">4Factories mette a disposizione un sistema di reportistica e Dashboard avanzato facile
          da usare e
          accessibile da
          qualsiasi dispositivo fisso o mobile, in Azienda o fuori sede. Le statistiche esposte in questa sezione sono
          sincronizzate
          in tempo reale con i dati di produzione per avere informazioni sempre aggiornate che permettono al
          management aziendale di
          prendere decisioni veloci e di attuare azioni correttive in caso di criticit&agrave;.</p>
        <div id="counter">
          <div class="cell">
            <div class="counter-value number-count" data-count="60">1</div>
            <i class="fas fa-plus secondary-color"></i>
            <div class="counter-info">Report<br>PDF</div>
          </div>
          <div class="cell">
            <div class="counter-value number-count" data-count="10">1</div>
            <i class="fas fa-plus secondary-color"></i>
            <div class="counter-info">Dashboard<br>Dinamici</div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 d-sm-none d-md-block d-none d-sm-block">
        <div echarts [options]="echartOptions" class="echart"></div>
      </div>
    </div>
  </div>
</div>

<div class="module-div">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="accordion">
          <div class="area-2">

            <div class="accordion-container" id="accordionOne">
              <h3 class="module-title"><i class="fas fa-th-large secondary-color"></i> Moduli principali</h3>
              <div class="item">
                <div id="headingOne">
                        <span data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                              aria-controls="collapseOne" role="button"
                              (click)="showPalmareImage = !showPalmareImage; showTabletImage = false;">
                            <span class="circle-numbering">1</span><span class="accordion-title">Applicazione Palmare Android</span>
                        </span>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionOne">
                  <div class="accordion-body text-justify">
                    <p>Tramite questa Progressive Web App é possibile monitorare e tracciare l’intero ciclo di
                      produzione
                      rilevando, fase per fase, tutte le
                      informazioni necessarie al controllo.<br>
                      Nel dettaglio 4Factories per Palmari è in grado di:</p>
                    <ul class="list-unstyled li-space-lg indent mt-2">
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire il carico linea</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire l'acquisizione del colaggio</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Rilevare i rotti del colaggio</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire l'acquisizione del collaudo</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire l'acquisizione della smaltatura</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire il carico del forno</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Rilevare i rotti nel carico forno</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire la scelta</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="item">
                <div id="headingTwo">
                        <span class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                              aria-controls="collapseTwo" role="button"
                              (click)="showTabletImage = !showTabletImage; showPalmareImage = false;">
                            <span class="circle-numbering">2</span><span class="accordion-title">Applicazione Desktop o Tablet</span>
                        </span>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionOne">
                  <div class="accordion-body text-justify">
                    <p>Questa Web Applications permette di gestire tutte le anagrafiche di produzione e di visualizzare
                      Dashboard e Report. <br>
                      Nello specifico 4Factories per Desktop/Tablet è in grado di:</p>
                    <ul class="list-unstyled li-space-lg indent mt-2">
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire gli stampi</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire le madri forma</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire le anagrafiche di produzione</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire dati riguardanti gli operai</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Produrre Report e Dashboard</div>
                      </li>
                      <li class="media">
                        <i class="fas fa-square"></i>
                        <div class="media-body">Gestire cotture e ricette dei forni</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 d-sm-none d-md-block d-none d-sm-block">
        <div class="image-container">
          <img *ngIf="showPalmareImage && !showTabletImage" height="520" class="img-fluid palmare-img"
               src="assets/img/Palmare.png"
               alt="alternative">
          <img *ngIf="showTabletImage && !showPalmareImage" class="img-fluid" src="assets/img/sceltaIMG.png"
               alt="alternative">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="grey-background-wrapper">
  <gur-contattaci></gur-contattaci>
</div>

<gur-footer></gur-footer>
