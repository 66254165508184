import {Component, OnInit} from '@angular/core';

declare function startPreloader(): any;

@Component({
  selector: 'gur-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    startPreloader();
  }

}
