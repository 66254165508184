import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'gur-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() public isNotHome: boolean;

  constructor(public router: Router) {
  }

  ngOnInit(): void {
  }

  public navigate(): void {
    this.router.navigate(['/']);
  }


}
