import {Component, OnInit} from '@angular/core';

declare function getMessanger(): any;

@Component({
  selector: 'gur-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    getMessanger();
  }

}
