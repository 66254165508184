<nav class="navbar navbar-expand-md navbar-dark navbar-custom fixed-top">
  <!-- Logo -->
  <a class="navbar-brand logo-img" [href]="!isNotHome ? '#header': null" (click)="isNotHome ? navigate(): null">
    <img class="img-fluid" src="assets/img/gurobofav.png" alt="alternative"></a>

  <!-- Mobile Button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-gurobo-default"
          aria-controls="navbar-gurobo-default" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-gurobo fas fa-bars"></span>
    <span class="navbar-gurobo fas fa-times"></span>
  </button>

  <div class="collapse navbar-collapse font-navbar" id="navbar-gurobo-default">
    <ul class="navbar-nav ml-auto">
      <ng-container *ngIf="!isNotHome; else noancor">
        <li class="nav-item">
          <a class="nav-link scrolling-page" href="#chisiamo">CHI SIAMO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrolling-page" href="#ourproducts">I NOSTRI PRODOTTI</a>
        </li>
      </ng-container>
      <ng-template #noancor>
        <li class="nav-item">
          <a class="nav-link scrolling-page" href="#" (click)="navigate()">HOME</a>
        </li>
      </ng-template>
      <li class="nav-item">
        <a class="nav-link scrolling-page" href="#contact">CONTATTI</a>
      </li>
    </ul>
    <!--    <span class="nav-item icons-socials">
                    <span class="fa-stack">
                        <a href="#your-link">
                            <span class="hexagon"></span>
                            <i class="fab fa-facebook-f fa-stack-1x"></i>
                        </a>
                    </span>
                    <span class="fa-stack">
                        <a href="#your-link">
                            <span class="hexagon"></span>
                            <i class="fab fa-twitter fa-stack-1x"></i>
                        </a>
                    </span>
                </span>-->
  </div>
</nav>
