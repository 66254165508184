import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonMessageService {

  timeout = 10000;

  constructor(private toastrService: ToastrService) {
  }

  public setSuccessMessage(text: string): void {
    this.toastrService.success(text,
      '',
      {timeOut: this.timeout, tapToDismiss: true, closeButton: false});
  }

  public setInfoMessage(text: string): void {
    this.toastrService.info(text,
      '',
      {timeOut: this.timeout, tapToDismiss: true, closeButton: false});
  }

  public setErrorMessage(text: string): void {
    this.toastrService.error(text,
      '',
      {timeOut: this.timeout, tapToDismiss: true, closeButton: false});
  }

  public setWarningMessage(text: string): void {
    this.toastrService.warning(text,
      '',
      {timeOut: this.timeout, tapToDismiss: true, closeButton: false});
  }

  public clearMessage(): void {
    this.toastrService.clear();
  }


}
