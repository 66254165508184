<div id="ourproducts" class="products">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title">I nostri prodotti</div>
        <h2>Scegli il prodotto <span class="secondary-color font-weight-bold">4</span><br> che si adatta alle tue
          esigenze</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">

        <div class="card card-one">
          <div class="card-body">
            <img class="img-fluid center-element" width="75" height="150" src="assets/img/4f_logo.png"
                 alt="alternative">
            <h3 class="card-title mt-3">
              <span class="secondary-color font-weight-bold">4</span>Factories
            </h3>
            <p class="text-justify">4Factories è un software gestionale che nasce per gestire, monitorare e tracciare
              l’intero ciclo di produzione, all’interno di una fabbrica che produce ceramica sanitaria, rilevando fase
              per fase tutte le
              informazioni necessarie al controllo di gestione.
            </p>
          </div>
          <div class="button-wrapper">
            <a class="btn-solid-link page-scroll" [routerLink]="['/4factories']">DETTAGLI</a>
          </div>
        </div>

        <!--        <div class="card">
                  <div class="card-body">
                    <img class="img-fluid center-element" width="75" height="150" src="assets/img/4track1920.png"
                         alt="alternative">
                    <h3 class="card-title mt-3">
                      <span class="secondary-color font-weight-bold">4</span>Warehouse
                    </h3>
                    <p class="text-justify">4Warehouse è un software in grado di governare i processi di magazzino a partire
                      dalle gestione delle anagrafiche, passando per il carico e lo scarico delle merci, fino alla gestione
                      delle movimentazioni e alla creazione di un inventario.</p>
                  </div>
                  <div class="button-wrapper">
                    <a class="btn-solid-link page-scroll" href="#callMe">DETTAGLI</a>
                  </div>
                </div>-->

        <div class="card">
          <div class="card-body">
            <img class="img-fluid center-element img-2" width="100" height="175"
                 src="assets/img/4orders821.png"
                 alt="alternative">
            <h3 class="card-title mt-3">
              <span class="secondary-color font-weight-bold">4</span>Orders
            </h3>
            <p class="text-justify">4Orders è un portale pensato per il B2B in grado di gestire ordini, preventivi,
              articoli, clienti e contatti; l’applicazione si integra con il gestionale 4Factories garantendo
              così
              alla tua azienda una gestione completa dei prodotti dalla produzione alla vendita.</p>
          </div>
          <div class="button-wrapper">
            <a class="btn-solid-link page-scroll" [routerLink]="['/4Orders']">DETTAGLI</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
