<div id="chisiamo" class="whoweare">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="text-container">
          <div class="section-title">Chi siamo</div>
          <h3>I tuoi partner per la<span class="secondary-color font-weight-bold"> Transizione 4.0</span></h3>
          <p class="text-justify">Gurobo è una start-up che nasce con l’obiettivo di supportare le aziende
            manifatturiere nei processi di trasformazione digitale e dematerializzazione tramite i suoi prodotti
            software realizzati su misura per ciascuna azienda.<br>
            Sfruttando la nostra esperienza consigliamo e affianchiamo i nostri clienti in tutto il percorso di
            informatizzazione
            ponendoci come obiettivo principale quello di "creare valore" migliorando i flussi ed i processi aziendali.<br>
            La nostra mission è portare i processi produttivi nel mondo di “domani”.
          </p>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="image-container">
          <img class="img-fluid" src="assets/img/laptop.jpg" alt="alternative">
        </div>
      </div>
    </div>
  </div>
</div>


<div class="sub-whoweare">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
                        <span class="fa-stack">
                            <span class="mini-circle-icon">
                              <i class="fas fa-wifi fa-stack-1x secondary-color"></i>
                            </span>
                        </span>
          <div class="card-body">
            <h4 class="card-title">Connettiamo la<span
              class="secondary-color font-weight-bold"> tua fabbrica</span>
            </h4>
            <p class="text-justify">Tramite dei connettori i nostri software si integrano facilmente con i
              gestionali ed ERP già presenti nella tua fabbrica.</p>
          </div>
        </div>

        <div class="card">
                        <span class="fa-stack">
                            <span class="mini-circle-icon"></span>
                            <i class="fas fa-microchip fa-stack-1x secondary-color"></i>
                        </span>
          <div class="card-body">
            <h4 class="card-title">Facilitiamo la<span
              class="secondary-color font-weight-bold"> tua innovazione</span>
            </h4>
            <p class="text-justify">Attraverso i nostri software innovativi puntiamo a facilitare
              i tuoi processi di trasformazione digitale e dematerializzazione.</p>
          </div>
        </div>

        <div class="card">
                        <span class="fa-stack">
                          <span class="mini-circle-icon"></span>
                          <i class="fas fa-sliders-h fa-stack-1x secondary-color"></i>
                        </span>
          <div class="card-body">
            <h4 class="card-title">Ci addattiamo alle <span
              class="secondary-color font-weight-bold"> tue esigenze</span>
            </h4>
            <p class="text-justify">Creiamo software flessibili e personalizzabili che
              mirano a facilitare la gestione e il monitoraggio dei tuoi processi aziendali.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
