<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="text-container about">
          <img class="img-fluid" width="400" src="assets/img/Logo.png" alt="alternative">
          <p class="white sub-title">Another step in the
            world of tomorrow</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-container">
          <h6 class="white mt-4">© 2021 Gurobo s.r.l.s. - 01033 Civita Castellana, Viterbo - Italia<br>
                                 Partita IVA 02348240561 - Cap. Sociale € 1.050 int. vers.</h6>
 <!--         <ul class="list-unstyled li-space-lg white">
            <li>
              <a class="white" href="#your-link">startupguide.com</a>
            </li>
            <li>
              <a class="white" href="terms-conditions.html">Terms & Conditions</a>
            </li>
            <li>
              <a class="white" href="privacy-policy.html">Privacy Policy</a>
            </li>
          </ul>-->
        </div>
      </div>
<!--      <div class="col-md-2">
        <div class="text-container">
          <h4>Tools</h4>
          <ul class="list-unstyled li-space-lg">
            <li>
              <a class="white" href="#your-link">businessgrowth.com</a>
            </li>
            <li>
              <a class="white" href="#your-link">influencers.com</a>
            </li>
            <li class="media">
              <a class="white" href="#your-link">optimizer.net</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-2">
        <div class="text-container">
          <h4>Partners</h4>
          <ul class="list-unstyled li-space-lg">
            <li>
              <a class="white" href="#your-link">unicorns.com</a>
            </li>
            <li>
              <a class="white" href="#your-link">staffmanager.com</a>
            </li>
            <li>
              <a class="white" href="#your-link">association.gov</a>
            </li>
          </ul>
        </div>
      </div>-->
    </div>
  </div>
</div>
